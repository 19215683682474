import { useMutation, useQuery } from "@apollo/client";
import {
	Cancel,
	CheckCircleOutline,
	HelpOutline,
	HighlightOff,
	PictureAsPdf,
	PivotTableChart,
} from "@mui/icons-material";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Toolbar,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Button,
	IconButton,
	Tooltip,
	useMediaQuery,
} from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { convertFromRaw } from "draft-js";
import gql from "graphql-tag";
import { useCallback, useState } from "react";
import { useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Invoice } from "src/atoms";
import { DeleteDialog, ManagePaymentDialog } from "src/organisms";
import { AdminPanel } from "src/templates";

const MY_RESERVATIONS = gql`
	query getBallroomRegistrations {
		ballroomRegistrations {
			reservation_id
			season_id
			user_id
			first_name
			last_name
			email
			gender
			phone_number
			paid
			payment
			season
			day
			course_id
			name
			start_date
			payment_date
			street
			city
			zip_code
			house_number
			school
			legal_representative
			phone_number_parent
			partners_name
			partners_email
		}
	}
`;

const COURSES_LIST = gql`
	query coursesList {
		courses {
			course_id
			name
		}
	}
`;

const SEASONS_LIST = gql`
	query ballroomSeasons {
		ballroomSeasons {
			season_id
			course_id
			season
			start_date
			day
			active
		}
	}
`;

const UPDATE_PAYMENT = gql`
	mutation updatePayment(
		$reservation_id: Int!
		$paid: Boolean!
		$payment: String
		$payment_date: String
		$user_id: Int!
	) {
		setBallroomRegistrationPayment(
			reservation_id: $reservation_id
			paid: $paid
			payment_date: $payment_date
			payment: $payment
			user_id: $user_id
		)
	}
`;

const REMOVE_BALLROOM_RESERVATION = gql`
	mutation reservationRemoval(
		$reservation_id: Int!
		$user_id: Int!
		$season_id: Int!
	) {
		removeBallroomRegistration(
			reservation_id: $reservation_id
			user_id: $user_id
			season_id: $season_id
		)
	}
`;

const DAY_MAP = {
	monday: "pondělí",
	tuesday: "úterý",
	wednesday: "středa",
	thursday: "čtvrtek",
	friday: "pátek",
	saturday: "sobota",
	sunday: "neděle",
};

const PAY_MAP = {
	cash: "Hotově",
	bank_transfer: "Bankovním převodem",
};

/**
 * Admin section content for managing ballroom registrations, printing invoices, setting payments etc.
 * @returns {Component} Ballroom Registrations table
 */
export function BallroomRegistrationsManagement() {
	const exportTableRef = useRef(null);

	let reservations = [];
	let coursesList = [];
	let seasonList = [];

	const tablet = useMediaQuery("(max-width:1200px)");

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);
	const [openPaymentDialog, setOpenPaymentDialog] = useState(false);

	const [openDialog, setOpenDialog] = useState(false);
	const [dialogID, setdialogID] = useState();
	const [seasonID, setSeasonID] = useState();
	const [deleteFunction, setDeleteFunction] = useState();
	const [userId, setUserId] = useState();
	const [selectedUserId, setSelectedUserId] = useState();

	const [registrationID, setRegistrationID] = useState("");
	const [payment, setPayment] = useState();
	const [payment_date, setPaymentDate] = useState(null);
	const [courseNameFilterValue, setCourseNameFilterValue] = useState("");
	const [seasonFilterValue, setSeasonFilterValue] = useState("");
	const [genderFilter, setGenderFilterValue] = useState("");
	const [courseText, setCourseText] = useState("");
	const [seasonText, setSeasonText] = useState("");
	const [genderText, setGenderText] = useState("");
	const [selected, setSelected] = useState([]);

	const eventsQuery = useQuery(MY_RESERVATIONS);
	const coursesListQuery = useQuery(COURSES_LIST);
	const seasonsListQuery = useQuery(SEASONS_LIST);

	const [removeBallroomRegistrationRequest] = useMutation(
		REMOVE_BALLROOM_RESERVATION,
		{
			onCompleted: (data) => {
				eventsQuery.refetch();
				setSnackState("success");
				setSnackMsg(data.removeBallroomRegistration);
				setSnackOpen(true);
			},
			onError: (error) => {
				setSnackState("error");
				setSnackMsg(error.message);
				setSnackOpen(true);
			},
		}
	);

	const [updatePaymentRequest] = useMutation(UPDATE_PAYMENT, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.setBallroomRegistrationPayment);
			setSnackOpen(true);
			setOpenPaymentDialog(false);
			setSelected([]);
			eventsQuery.refetch();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	if (eventsQuery.data) {
		reservations = eventsQuery.data.ballroomRegistrations.filter(
			(reservation) => {
				if (
					courseNameFilterValue !== "" &&
					reservation.course_id !== courseNameFilterValue
				) {
					return false;
				}
				if (
					seasonFilterValue !== "" &&
					reservation.season_id !== seasonFilterValue
				) {
					return false;
				}
				if (
					genderFilter !== "" &&
					reservation.gender !== genderFilter
				) {
					return false;
				}
				return true;
			}
		);
	}

	if (coursesListQuery.data) {
		coursesList = coursesListQuery.data.courses.filter((course) => {
			if (course.course_id < 3) {
				return true;
			}
			return false;
		});
	}

	if (seasonsListQuery.data) {
		seasonList = seasonsListQuery.data.ballroomSeasons.filter((season) => {
			if (
				courseNameFilterValue !== "" &&
				season.course_id !== courseNameFilterValue
			) {
				return false;
			}
			if (season.course_id === 3) {
				return false;
			}
			if (season.active !== true) {
				return false;
			}
			return true;
		});
	}

	const handleCourseFilter = (event) => {
		setCourseNameFilterValue(event.target.value);
		setSeasonFilterValue("");
	};

	const handleSeasonFilter = (event) => {
		setSeasonFilterValue(event.target.value);
	};
	const handleGenderFilter = (event) => {
		setGenderFilterValue(event.target.value);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1 && selected.length === 0) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleSetPayment = useCallback(() => {
		let date = payment_date;
		if (date !== null) {
			date = `${payment_date.getFullYear()}-${
				payment_date.getMonth() + 1
			}-${payment_date.getDate()}`;
		}
		updatePaymentRequest({
			variables: {
				reservation_id: registrationID,
				paid: true,
				payment_date: date,
				payment: payment,
				user_id: parseInt(selectedUserId),
			},
		});
	}, [
		payment,
		payment_date,
		registrationID,
		selectedUserId,
		updatePaymentRequest,
	]);

	const handlePaymentNotReceived = useCallback(() => {
		updatePaymentRequest({
			variables: {
				reservation_id: registrationID,
				paid: false,
				payment_date: null,
				payment: payment,
				user_id: parseInt(selectedUserId),
			},
		});
	}, [payment, registrationID, selectedUserId, updatePaymentRequest]);

	const handleRemoveBallroomRegistration = useCallback(
		(reservation_id, user_id, season_id) => {
			removeBallroomRegistrationRequest({
				variables: {
					reservation_id: parseInt(reservation_id),
					user_id: parseInt(user_id),
					season_id: parseInt(season_id),
				},
			});
		},
		[removeBallroomRegistrationRequest]
	);

	return (
		<AdminPanel
			title="Přihlášky společenské tance"
			snackState={snackState}
			snackMsg={snackMsg}
			snackOpen={snackOpen}
			setSnackOpen={setSnackOpen}
			adminsOnly={true}
		>
			<Toolbar
				sx={{
					display: "flex",
					flexDirection: "row",
					flexWrap: "wrap",
				}}
			>
				<FormControl
					sx={{ m: 1, width: tablet ? "100%" : "300px" }}
					size="small"
					color="secondary"
				>
					<InputLabel id="demo-select-small">Název kurzu</InputLabel>
					<Select
						labelId="course-name-select-id-label"
						id="course-name-select"
						value={courseNameFilterValue}
						onChange={(e) => {
							handleCourseFilter(e);
							setTimeout(() => {
								setCourseText(
									document.getElementById(
										"course-name-select"
									).outerText
								);
							}, 300);
						}}
						label="Název kurzu"
					>
						<MenuItem value="">
							<em>Žádný</em>
						</MenuItem>
						{coursesListQuery.data &&
							coursesList.map((course) => (
								<MenuItem
									key={course.course_id}
									value={course.course_id}
								>
									{convertFromRaw(
										JSON.parse(course.name)
									).getPlainText()}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<FormControl
					sx={{ m: 1, width: tablet ? "100%" : "200px" }}
					size="small"
					color="secondary"
				>
					<InputLabel id="demo-select-small">Sezóna</InputLabel>
					<Select
						labelId="season-select-id-label"
						id="season-select"
						value={seasonFilterValue}
						onChange={(e) => {
							handleSeasonFilter(e);
							setTimeout(() => {
								setSeasonText(
									document.getElementById("season-select")
										.outerText
								);
							}, 300);
						}}
						label="Sezóna"
					>
						<MenuItem value="">
							<em>Žádná</em>
						</MenuItem>
						{seasonsListQuery.data &&
							seasonList.map((season) => (
								<MenuItem
									name={`${season.season} - ${
										DAY_MAP[season.day]
									}`}
									key={season.season_id}
									value={season.season_id}
								>
									{season.season} - {DAY_MAP[season.day]}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<FormControl
					sx={{ m: 1, width: tablet ? "100%" : "200px" }}
					size="small"
					color="secondary"
				>
					<InputLabel id="demo-select-small">Pohlaví</InputLabel>
					<Select
						labelId="gender-select-id-label"
						id="gender-select"
						value={genderFilter}
						onChange={(e) => {
							handleGenderFilter(e);
							setTimeout(() => {
								setGenderText(
									document.getElementById("gender-select")
										.outerText
								);
							}, 300);
						}}
						label="Pohlaví"
					>
						<MenuItem value="">
							<em>Žádné</em>
						</MenuItem>
						<MenuItem key="male" value="male">
							Muž
						</MenuItem>
						<MenuItem key="female" value="female">
							Žena
						</MenuItem>
						<MenuItem key="pair" value="pair">
							Pár
						</MenuItem>
					</Select>
				</FormControl>
				<DownloadTableExcel
					filename={
						courseText !== "" ||
						seasonText !== "" ||
						genderText !== ""
							? `${courseText} ${seasonText} ${genderText}`
							: "Přihlášky společenské tance"
					}
					sheet={
						courseText !== "" ||
						seasonText !== "" ||
						genderText !== ""
							? `${courseText} ${seasonText} ${genderText}`
							: "Přihlášky společenské tance"
					}
					currentTableRef={exportTableRef.current}
				>
					<Tooltip title="Export do excelu">
						<IconButton
							variant="contained"
							color="secondary"
							sx={{ ml: "auto", mr: "5px" }}
						>
							<PivotTableChart />
						</IconButton>
					</Tooltip>
				</DownloadTableExcel>
				<PDFDownloadLink
					document={
						<Invoice
							reservations={reservations.filter((res) => {
								if (res.payment === "cash") {
									return true;
								}
								return false;
							})}
						/>
					}
					fileName="Příjmové doklady.pdf"
				>
					<Tooltip title="Export příjmových dokladů">
						<IconButton color="secondary" variant="contained">
							<PictureAsPdf />
						</IconButton>
					</Tooltip>
				</PDFDownloadLink>
				{selected.length > 0 && (
					<Button
						variant="contained"
						color="secondary"
						sx={{ ml: "auto" }}
						onClick={() => {
							setRegistrationID(selected[0]);

							setOpenPaymentDialog(true);
						}}
					>
						Zaznamenat platbu
					</Button>
				)}
			</Toolbar>
			<TableContainer sx={{ maxHeight: "400px" }}>
				<Typography variant="body1" color="initial" sx={{ ml: 2 }}>
					Počet řádků: {reservations.length}
				</Typography>
				<Table
					sx={{
						minWidth: 650,
						borderCollapse: "separate",
						px: "10px",
					}}
					aria-label="simple table"
					size="small"
					ref={exportTableRef}
				>
					<TableHead>
						<TableRow>
							<TableCell align="right"></TableCell>
							<TableCell align="left" sx={{ display: "none" }}>
								ID registrace
							</TableCell>
							<TableCell align="left">Příjmení</TableCell>
							<TableCell align="left">Jméno</TableCell>
							<TableCell align="left">E-mail</TableCell>
							<TableCell align="left" sx={{ display: "none" }}>
								Partner
							</TableCell>
							<TableCell align="left" sx={{ display: "none" }}>
								E-mail partnera
							</TableCell>
							<TableCell align="left">Telefonní číslo</TableCell>
							<TableCell align="left" sx={{ display: "none" }}>
								Trvalé bydliště
							</TableCell>
							<TableCell align="left" sx={{ display: "none" }}>
								Škola
							</TableCell>
							<TableCell align="left" sx={{ display: "none" }}>
								Zákonný zástupce
							</TableCell>
							<TableCell align="left" sx={{ display: "none" }}>
								Zákonný zástupce - telefon
							</TableCell>
							<TableCell align="left">Platba</TableCell>
							<TableCell align="center">Zaplaceno</TableCell>
							<TableCell align="center">Datum platby</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{eventsQuery.data &&
							eventsQuery.data.ballroomRegistrations !== null &&
							reservations.map((reservation) => {
								const isItemSelected = isSelected(
									reservation.reservation_id
								);
								return (
									<TableRow
										key={reservation.reservation_id}
										hover
										role="checkbox"
										tabIndex={-1}
										onClick={(event) => {
											handleClick(
												event,
												reservation.reservation_id
											);
											setPayment(reservation.payment);
											setSelectedUserId(
												reservation.user_id
											);
											if (
												reservation.payment_date !==
												null
											) {
												setPaymentDate(
													new Date(
														parseInt(
															reservation.payment_date
														)
													)
												);
											} else {
												setPaymentDate(null);
											}
										}}
										aria-checked={isItemSelected}
										selected={isItemSelected}
									>
										<TableCell align="left">
											<IconButton
												aria-label="cancel-event"
												onClick={() => {
													setOpenDialog(true);
													setdialogID(
														reservation.reservation_id
													);
													setSeasonID(
														reservation.season_id
													);
													setUserId(
														reservation.user_id
													);
													setDeleteFunction(
														() =>
															handleRemoveBallroomRegistration
													);
												}}
												color="error"
											>
												<Cancel />
											</IconButton>
										</TableCell>
										<TableCell
											align="left"
											sx={{ display: "none" }}
										>
											{reservation.reservation_id}
										</TableCell>
										<TableCell align="left">
											{reservation.last_name}
										</TableCell>
										<TableCell align="left">
											{reservation.first_name}
										</TableCell>
										<TableCell align="left">
											{reservation.email}
										</TableCell>
										<TableCell
											align="left"
											sx={{ display: "none" }}
										>
											{reservation.partners_name}
										</TableCell>
										<TableCell
											align="left"
											sx={{ display: "none" }}
										>
											{reservation.partners_email}
										</TableCell>
										<TableCell align="left">
											{reservation.phone_number}
										</TableCell>
										<TableCell
											align="left"
											sx={{ display: "none" }}
										>
											{reservation.street}{" "}
											{reservation.house_number}
											{", "}
											{reservation.city}{" "}
											{reservation.zip_code}
										</TableCell>
										<TableCell
											align="left"
											sx={{ display: "none" }}
										>
											{reservation.school}
										</TableCell>
										<TableCell
											align="left"
											sx={{ display: "none" }}
										>
											{reservation.legal_representative}
										</TableCell>
										<TableCell
											align="left"
											sx={{ display: "none" }}
										>
											{reservation.phone_number_parent}
										</TableCell>
										<TableCell align="left">
											{PAY_MAP[reservation.payment]}
										</TableCell>
										<TableCell align="center">
											{reservation.paid === false ? (
												<HighlightOff
													sx={{
														color: "response.error",
													}}
												/>
											) : reservation.paid === true ? (
												<CheckCircleOutline
													sx={{
														color: "response.success",
													}}
												/>
											) : (
												<HelpOutline
													sx={{
														color: "#e69602",
													}}
												/>
											)}
										</TableCell>
										<TableCell align="center">
											{reservation.payment_date !==
												null &&
											reservation.payment_date !==
												undefined &&
											reservation.payment_date !== ""
												? new Date(
														parseInt(
															reservation.payment_date
														)
												  ).toLocaleDateString("cs-CZ")
												: ""}
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
			<ManagePaymentDialog
				open={openPaymentDialog}
				setOpen={setOpenPaymentDialog}
				action={() => {
					handleSetPayment();
				}}
				setPayment={setPayment}
				setPaymentDate={setPaymentDate}
				payment_date={payment_date}
				payment={payment}
				handlePaymentNotReceived={handlePaymentNotReceived}
			/>
			<DeleteDialog
				open={openDialog}
				setOpen={setOpenDialog}
				id={dialogID}
				deleteFunction={deleteFunction}
				season_id={seasonID}
				user_id={userId}
			/>
		</AdminPanel>
	);
}
