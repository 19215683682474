import { Button, Tooltip, IconButton, Box, Select, InputLabel, MenuItem, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { AdminPanel } from "src/templates";
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	gridFilteredSortedRowIdsSelector,
} from "@mui/x-data-grid";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { convertFromRaw } from "draft-js";
import { InvoiceOrders } from "src/atoms";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PictureAsPdf } from "@mui/icons-material";
import { useRef } from "react";
import { ManagePaymentDialog } from "../Dialogs/ManagePaymentDialog";
import { DeleteDialog } from "../Dialogs/DeleteDialog";

const UPDATE_PAYMENT = gql`
	mutation updatePayment(
		$ticket_order_id: Int!
		$paid: Boolean!
		$payment: String
		$payment_date: String
		$user_id: Int!
	) {
		setTicketOrderPayment(
			ticket_order_id: $ticket_order_id
			paid: $paid
			payment_date: $payment_date
			payment: $payment
			user_id: $user_id
		)
	}
`;

const ORDERS = gql`
	query ticketOrdersCurrentSeasonByCourseId($courseId: Int) {
		ticketOrdersCurrentSeasonByCourseId(courseId: $courseId) {
			ticket_order_id
			course_id
			user_id
			season_id
			season
			tickets
			price
			paid
			payment
			date
			ticket_type
			first_name
			last_name
			street
			house_number
			zip_code
			city
			email
			name
			free_tick
		}
	}
`;

const REMOVE_ORDER = gql`
	mutation removeTicketOrder(
		$ticket_order_id: Int!
		$user_id: Int!
		$season_id: Int!
	) {
		removeTicketOrder(
			ticket_order_id: $ticket_order_id
			user_id: $user_id
			season_id: $season_id
		)
	}
`;

const TYPE_MAP = {
	single: "Samostatné",
	single_student: "Samostatné student",
	ten: "Deset",
	ten_student: "Deset student",
	twenty: "Dvacet",
	twenty_student: "Dvacet student",
	individual: "Individuální",
};

const COURSES_QUERY = gql`
	query courses {
		courses {
			course_id
			name
			description
			short_description
			cover
		}
	}
`;

/**
 * Admin section content with table dance studio orders.
 * It is special MUI component to enable fast filtering of columns and values.
 * Enables export to CSV and print Invoices for people who decided to pay with cash.
 * @returns {Component} DataGrid
 */
export function DanceStudioOrders() {
	const exportTableRef = useRef(null);

	const [ticketOrdersList, setTicketOrdersList] = useState([]);
	const [orderID, setOrderID] = useState("");
	const [payment, setPayment] = useState();
	const [payment_date, setPaymentDate] = useState(null);
	const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
	const [userID, setUserID] = useState("");
	const [seasonID, setSeasonID] = useState("");
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [deleteFunction, setDeleteFunction] = useState();
	const [selectedCourse, setSelectedCourse] = useState("");
	const [courseOptions, setCourseOptions] = useState([]);

	const [filteredIds, setFilteredIds] = useState([]);

	const [orders, setOrders] = useState([]);

	const [snackState, setSnackState] = useState();
	const [snackMsg, setSnackMsg] = useState();
	const [snackOpen, setSnackOpen] = useState(false);

	const { data, loading, refetch } = useQuery(ORDERS, {
		notifyOnNetworkStatusChange: true,
	});
	const { data: coursesData } = useQuery(COURSES_QUERY);

	useEffect(() => {
		if (coursesData && coursesData.courses.length > 0) {
			const filteredCourses = coursesData.courses.filter(c => c.course_id >= 3);

			let initialCourseId = "";
			if (filteredCourses.length > 0) initialCourseId = filteredCourses[0].course_id;
			setSelectedCourse(initialCourseId);
			setCourseOptions(filteredCourses);
	
			// Fetch orders for the initial course
			refetch({ courseId: initialCourseId });
		}
	}, [coursesData]);

	useEffect(() => {
		if (data) setOrders(data.ticketOrdersCurrentSeasonByCourseId);
	}, [data]);

	const columns = [
		{
			field: "last_name",
			headerName: "Příjmení",
			width: 120,
		},
		{
			field: "first_name",
			headerName: "Jméno",
			width: 120,
		},
		{
			field: "email",
			headerName: "E-mail",
			width: 200,
		},
		{
			field: "name",
			headerName: "Kurz",
			sortable: false,
			width: 180,
			valueGetter: (params) =>
				`${convertFromRaw(JSON.parse(params.row.name)).getPlainText()}`,
		},
		{
			field: "season",
			headerName: "Sezóna",
			sortable: false,
			width: 120,
		},
		{
			field: "ticket_type",
			headerName: "Balíček",
			sortable: false,
			width: 150,
			valueGetter: (params) => `${TYPE_MAP[params.row.ticket_type]}`,
		},
		{
			field: "free_tick",
			headerName: "Počet zaplacených nevychozených vstupů",
			sortable: false,
			width: 150,
		},
		{
			field: "tickets",
			headerName: "Vstupů",
			sortable: false,
			width: 80,
		},
		{
			field: "price",
			headerName: "Cena",
			sortable: false,
			width: 90,
		},
		{
			field: "payment",
			headerName: "Platba",
			sortable: false,
			width: 160,
			valueGetter: (params) =>
				`${
					params.row.payment === "cash"
						? "Hotově"
						: "Bankovním převodem"
				}`,
		},
		{
			field: "paid",
			headerName: "Zaplaceno",
			sortable: false,
			width: 90,
			valueGetter: (params) => `
        ${
			params.row.paid === false
				? "Ne"
				: params.row.paid === true
				? "Ano"
				: "Ne"
		}
      `,
		},
		{
			field: "date",
			headerName: "Datum platby",
			type: "date",
			sortable: false,
			width: 120,
			valueGetter: (params) =>
				`${new Date(parseInt(params.row.date)).toLocaleDateString(
					"cs-CZ"
				)}`,
		},
	];

	useEffect(() => {
		if (data) {
			setTicketOrdersList(data.ticketOrdersCurrentSeasonByCourseId);
		}
	}, [data]);

	const [removeOrderRequest] = useMutation(REMOVE_ORDER, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.removeTicketOrder);
			setSnackOpen(true);
			refetch();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handleRemoveTicketOrder = useCallback(
		(ticket_order_id, user_id, season_id) => {
			removeOrderRequest({
				variables: {
					ticket_order_id: ticket_order_id,
					user_id: user_id,
					season_id: season_id,
				},
			});
		},
		[removeOrderRequest]
	);

	const [updatePaymentRequest] = useMutation(UPDATE_PAYMENT, {
		onCompleted: (data) => {
			setSnackState("success");
			setSnackMsg(data.setTicketOrderPayment);
			setSnackOpen(true);
			setOpenPaymentDialog(false);
			refetch();
		},
		onError: (error) => {
			setSnackState("error");
			setSnackMsg(error.message);
			setSnackOpen(true);
		},
	});

	const handlePaymentNotReceived = useCallback(() => {
		updatePaymentRequest({
			variables: {
				ticket_order_id: parseInt(orderID),
				paid: false,
				payment_date: null,
				payment: payment,
				user_id: parseInt(userID),
			},
		});
	}, [orderID, payment, updatePaymentRequest, userID]);

	const handleSetPayment = useCallback(() => {
		let date = payment_date;
		if (date !== null) {
			date = `${payment_date.getFullYear()}-${payment_date.getMonth() + 1
				}-${payment_date.getDate()}`;
		}
		updatePaymentRequest({
			variables: {
				ticket_order_id: parseInt(orderID),
				paid: true,
				payment_date: date,
				payment: payment,
				user_id: parseInt(userID),
			},
		});
	}, [orderID, payment, payment_date, updatePaymentRequest, userID]);

	const handleSelectionChange = (e) => {
		const courseId = e.target.value || "";
		setSelectedCourse(courseId);

		// Refetch the orders with the new course_id
		refetch({ courseId: parseInt(courseId) });
	}

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<PDFDownloadLink
					document={
						<InvoiceOrders
							reservations={orders.filter((res) => {
								if (
									res.payment === "cash" &&
									filteredIds.includes(
										parseInt(res.ticket_order_id)
									)
								) {
									return true;
								}
								return false;
							})}
						/>
					}
					fileName="Příjmové doklady.pdf"
				>
					<Tooltip title="Export příjmových dokladů">
						<IconButton color="secondary" variant="contained">
							<PictureAsPdf />
						</IconButton>
					</Tooltip>
				</PDFDownloadLink>
				<GridToolbarExport
					csvOptions={{
						fileName: "Objednávky taneční studio Ká",
						delimiter: ";",
						utf8WithBom: true,
					}}
				/>
				{orderID !== "" && (
					<Button
						variant="contained"
						color="error"
						sx={{ ml: "auto", color: "white !important" }}
						onClick={() => {
							setOpenDeleteDialog(true);
							setDeleteFunction(() => handleRemoveTicketOrder);
						}}
					>
						Zrušit objednávku
					</Button>
				)}
				{orderID !== "" && (
					<Button
						variant="contained"
						color="secondary"
						sx={{ ml: "10px", color: "white !important" }}
						onClick={() => {
							setOpenPaymentDialog(true);
						}}
					>
						Zaznamenat platbu
					</Button>
				)}
			</GridToolbarContainer>
		);
	}

	return (
		<AdminPanel
			title="Objednávky taneční studio"
			snackState={snackState}
			snackMsg={snackMsg}
			snackOpen={snackOpen}
			setSnackOpen={setSnackOpen}
			adminsOnly={true}
		>
			<InputLabel id="demo-simple-select-label" style={{ marginTop: "10px" }}>Kurz:</InputLabel>
			<Select
				style={{ marginBottom: "20px" }}
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={selectedCourse}
				label="Kurz:"
				onChange={(e) => handleSelectionChange(e)}
			>
				{
					courseOptions && courseOptions.map((course) => (
                        <MenuItem key={course.course_id} value={course.course_id}>
                            {JSON.parse(course.name).blocks[0].text}
                        </MenuItem>
                    ))
				}
			</Select>
			{loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ height: "500px" }}>
                    <DataGrid
                        ref={exportTableRef}
                        components={{ Toolbar: CustomToolbar }}
                        getRowId={(row) => row.ticket_order_id}
                        rows={ticketOrdersList}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        experimentalFeatures={{ newEditingApi: true }}
                        onStateChange={(state) => {
                            setFilteredIds(gridFilteredSortedRowIdsSelector(state));
                        }}
                        onSelectionModelChange={(id) => {
                            setOrderID(id[0]);
                            let order = data.ticketOrdersCurrentSeasonByCourseId.filter((order) => {
                                if (
                                    parseInt(order.ticket_order_id) ===
                                    parseInt(id[0])
                                ) {
                                    return true;
                                }
                                return false;
                            })[0];
                            if (order !== null && order !== undefined) {
                                setUserID(order.user_id);
                                setSeasonID(order.season_id);
                            }
                        }}
                    />
                </Box>
            )}
			<ManagePaymentDialog
				open={openPaymentDialog}
				setOpen={setOpenPaymentDialog}
				action={() => {
					handleSetPayment();
				}}
				setPayment={setPayment}
				setPaymentDate={setPaymentDate}
				payment_date={payment_date}
				payment={payment}
				handlePaymentNotReceived={handlePaymentNotReceived}
			/>
			<DeleteDialog
				open={openDeleteDialog}
				setOpen={setOpenDeleteDialog}
				id={orderID}
				deleteFunction={deleteFunction}
				season_id={seasonID}
				user_id={userID}
			/>
		</AdminPanel>
	);
}
